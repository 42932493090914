import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box, Typography, IconButton, TextField, Button,
    List, CircularProgress
} from '@mui/material';
import {
    List as ListIcon,
    ShoppingBasket as ShoppingBasketIcon,
    Add as AddIcon
} from '@mui/icons-material';
import { getShoppingLists, createShoppingList, deleteShoppingList } from '../services/api';
import Lottie from 'lottie-react';
import emptyListAnimation from '../assets/empty-list.json';
import ShoppingListItem from '../components/ShoppingListItem';

function ShoppingList() {
    const [lists, setLists] = useState([]);
    const [newListName, setNewListName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchShoppingLists();
    }, []);

    const fetchShoppingLists = async () => {
        setIsLoading(true);
        try {
            const response = await getShoppingLists();
            setLists(response.data.data);
        } catch (error) {
            console.error('Error fetching shopping lists:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreateList = async () => {
        if (newListName.trim() === '') return;
        try {
            await createShoppingList(newListName);
            setNewListName('');
            fetchShoppingLists();
        } catch (error) {
            console.error('Error creating shopping list:', error);
        }
    };

    const handleDeleteList = async (id) => {
        try {
            await deleteShoppingList(id);
            fetchShoppingLists();
        } catch (error) {
            console.error('Error deleting shopping list:', error);
        }
    };

    const handleListClick = (listId) => {
        navigate(`/list/${listId}`);
    };

    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
    };

    const renderContent = () => {
        if (isLoading) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                </Box>
            );
        }

        if (lists.length === 0) {
            return (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                    <Lottie animationData={emptyListAnimation} style={{ width: 300, height: 300 }} />
                    <Typography variant="h6" color="text.secondary" mt={2}>
                        No shopping lists yet. Create one!
                    </Typography>
                </Box>
            );
        }

        return (
            <List sx={{ width: '100%' }}>
                {lists.map((list) => (
                    <ShoppingListItem
                        key={list._id}
                        list={list}
                        isEditMode={isEditMode}
                        onDelete={handleDeleteList}
                        onClick={() => handleListClick(list._id)}
                    />
                ))}
            </List>
        );
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', pb: '56px' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                bgcolor: 'background.paper',
                boxShadow: 1
            }}>
                <Box display="flex" alignItems="center">
                    <ShoppingBasketIcon sx={{ mr: 1 }} />
                    <Typography variant="h5">Shopping lists</Typography>
                </Box>
                <IconButton onClick={toggleEditMode}>
                    <ListIcon color={isEditMode ? 'primary' : 'action'} />
                </IconButton>
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
                {renderContent()}
            </Box>
            <Box elevation={4} sx={{
                p: 2,
                bgcolor: 'background.paper',
                position: 'sticky',
                bottom: 0
            }}>
                <Box display="flex" alignItems="center">
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={newListName}
                        onChange={(e) => setNewListName(e.target.value)}
                        placeholder="New list name"
                        sx={{ mr: 2 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateList}
                        startIcon={<AddIcon />}
                    >
                        Add
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default ShoppingList;