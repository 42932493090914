import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import ShoppingList from './pages/ShoppingList';
import ShoppingListDetail from './pages/ShoppingListDetail';
import Profile from './pages/Profile';
import Layout from './components/Layout';
import Login from './components/Login';
import Register from './components/Register';
import Onboarding from './pages/Onboarding';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';

function App() {
    return (
        <AuthProvider>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/onboarding" element={<PrivateRoute><Onboarding /></PrivateRoute>} />
                <Route path="/" element={<PrivateRoute><Layout><Home /></Layout></PrivateRoute>} />
                <Route path="/list" element={<PrivateRoute><Layout><ShoppingList /></Layout></PrivateRoute>} />
                <Route path="/list/:id" element={<PrivateRoute><Layout><ShoppingListDetail /></Layout></PrivateRoute>} />
                <Route path="/profile" element={<PrivateRoute><Layout><Profile /></Layout></PrivateRoute>} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </AuthProvider>
    );
}

export default App;