import React from 'react';
import {
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography
} from '@mui/material';
import {
    ChevronRight as ChevronRightIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';

const ShoppingListItem = ({ list, isEditMode, onDelete, onClick }) => {
    return (
        <ListItem
            onClick={onClick}
            sx={{
                mb: 2,
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 1,
                cursor: 'pointer'
            }}
        >
            <ListItemText
                primary={<Typography variant="h6">{list.name}</Typography>}
                secondary={`${list.items.length} products`}
            />
            <ListItemSecondaryAction>
                {isEditMode && (
                    <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete(list._id);
                        }}
                    >
                        <DeleteIcon color="error" />
                    </IconButton>
                )}
                <IconButton edge="end" aria-label="details">
                    <ChevronRightIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default ShoppingListItem;