import React, { createContext, useState, useEffect, useCallback } from 'react';
import api, { login as apiLogin, register as apiRegister } from '../services/api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);

    const checkAuth = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (token && !user) {
            try {
                api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                const response = await api.get('/users/me');
                setUser(response.data.data);
                setIsAuthenticated(true);
            } catch (error) {
                console.error('Error verifying token:', error);
                localStorage.removeItem('token');
                delete api.defaults.headers.common['Authorization'];
                setIsAuthenticated(false);
                setUser(null);
            }
        } else if (!token) {
            setIsAuthenticated(false);
            setUser(null);
        }
        setIsLoading(false);
    }, [user]);

    useEffect(() => {
        checkAuth();
    }, [checkAuth]);

    const login = async (email, password) => {
        try {
            const response = await apiLogin(email, password);
            localStorage.setItem('token', response.data.token);
            api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
            setUser(response.data.user);
            setIsAuthenticated(true);
            return response.data.user;
        } catch (error) {
            throw error;
        }
    };

    const register = async (name, email, password) => {
        try {
            const response = await apiRegister(name, email, password);
            localStorage.setItem('token', response.data.token);
            api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
            setUser(response.data.user);
            setIsAuthenticated(true);
            return response.data.user;
        } catch (error) {
            throw error;
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        delete api.defaults.headers.common['Authorization'];
        setUser(null);
        setIsAuthenticated(false);
    };

    const updateOnboardingStatus = (status) => {
        setUser(prevUser => ({
            ...prevUser,
            hasCompletedOnboarding: status
        }));
    };

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            setIsAuthenticated,
            isLoading,
            user,
            setUser,
            login,
            register,
            logout,
            updateOnboardingStatus,
            checkAuth
        }}>
            {children}
        </AuthContext.Provider>
    );
};