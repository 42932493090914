import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box, Typography, IconButton, TextField, Button,
    Checkbox, CircularProgress, Paper
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import {
    ArrowBack as ArrowBackIcon,
    Check as CheckIcon,
    DragIndicator as DragIndicatorIcon
} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {
    getShoppingList, addItemToShoppingList, deleteItem,
    updateItem, getShoppingListSuggestions
} from '../services/api';

function ShoppingListDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [shoppingList, setShoppingList] = useState(null);
    const [items, setItems] = useState([]);
    const [newItemInput, setNewItemInput] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [suggestion, setSuggestion] = useState(null);
    const inputRef = useRef(null);

    const fetchSuggestion = useCallback(async () => {
        try {
            const response = await getShoppingListSuggestions(id);
            if (response.data.data.length > 0) {
                setSuggestion(response.data.data[0]);
            } else {
                setSuggestion(null);
            }
        } catch (error) {
            console.error('Error getting suggestion:', error);
            setSuggestion(null);
        }
    }, [id]);

    const fetchShoppingList = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await getShoppingList(id);
            setShoppingList(response.data.data);
            setItems(response.data.data.items);
            if (response.data.data.items.length >= 2) {
                await fetchSuggestion();
            }
        } catch (error) {
            console.error('Error fetching shopping list:', error);
        } finally {
            setIsLoading(false);
        }
    }, [id, fetchSuggestion]);

    useEffect(() => {
        fetchShoppingList();
    }, [fetchShoppingList]);


    const handleAddItem = async (e) => {
        e.preventDefault();
        if (newItemInput.trim() === '') return;

        try {
            await addItemToShoppingList(id, { itemInput: newItemInput });
            setNewItemInput('');
            await fetchShoppingList();
        } catch (error) {
            console.error('Error adding item:', error);
        }
    };

    const handleDeleteItem = async (itemId) => {
        try {
            await deleteItem(itemId);
            // Update the local state
            setItems(prevItems => prevItems.filter(item => item._id !== itemId));
            // Check if we need to fetch a new suggestion
            if (items.length === 2) {
                await fetchSuggestion();
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleToggleComplete = async (item) => {
        try {
            const updatedItem = { ...item, completed: !item.completed };
            await updateItem(item._id, { completed: updatedItem.completed });

            // Update the local state
            setItems(prevItems =>
                prevItems.map(i => i._id === item._id ? updatedItem : i)
            );
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleGoBack = () => {
        navigate('/list');
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = Array.from(items);
        const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, reorderedItem);

        setItems(reorderedItems);
        // TODO: Implement backend call to update item order
        // updateItemOrder(reorderedItems.map(item => item._id));
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!shoppingList) {
        return (
            <Typography variant="h6" align="center" mt={4}>
                Shopping list not found
            </Typography>
        );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', pb: '56px' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                bgcolor: 'background.paper',
                boxShadow: 1
            }}>
                    <IconButton edge="start" color="inherit" onClick={handleGoBack} aria-label="back">
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        {shoppingList.name}
                    </Typography>
            </Box>

            <Box sx={{ flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                        {(provided) => (
                            <Box
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                sx={{
                                    flexGrow: 1,
                                    overflowY: 'auto',
                                    p: 2
                                }}
                            >
                                {items.map((item, index) => (
                                    <Draggable key={item._id} draggableId={item._id} index={index}>
                                        {(provided) => (
                                            <Box
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                sx={{ mb: 2 }}
                                            >
                                                <Paper
                                                    elevation={2}
                                                    sx={{
                                                        p: 2,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        bgcolor: item.completed ? 'action.disabledBackground' : 'background.paper'
                                                    }}
                                                >
                                                    <DragIndicatorIcon color="action" sx={{ mr: 1, cursor: 'move' }} />
                                                    <Checkbox
                                                        checked={item.completed}
                                                        onChange={() => handleToggleComplete(item)}
                                                        color="primary"
                                                    />
                                                    <Box flexGrow={1}>
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                textDecoration: item.completed ? 'line-through' : 'none',
                                                                color: item.completed ? 'text.disabled' : 'text.primary'
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {`${item.quantity} ${item.unit} - ${item.category}`}
                                                        </Typography>
                                                        {item.completed && item.updatedAt && (
                                                            <Typography variant="caption" color="text.disabled">
                                                                {`Completed on: ${formatDate(item.updatedAt)}`}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                    <IconButton
                                                        onClick={() => handleDeleteItem(item._id)}
                                                        edge="end"
                                                        aria-label="delete"
                                                        sx={{ color: 'error.main' }}
                                                    >
                                                        <CancelIcon />
                                                    </IconButton>
                                                </Paper>
                                            </Box>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>

                {suggestion && (
                    <Paper elevation={3} sx={{ p: 2, m: 2 }}>
                        <Typography variant="body1" mb={1}>
                            Suggested: {suggestion}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setNewItemInput(suggestion);
                                setSuggestion(null);
                                inputRef.current.focus();
                            }}
                        >
                            Add Suggestion
                        </Button>
                    </Paper>
                )}
            </Box>

            <Box
                component="form"
                onSubmit={handleAddItem}
                sx={{
                    p: 2,
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgcolor: 'background.paper'
                }}
            >
                <TextField
                    inputRef={inputRef}
                    fullWidth
                    variant="outlined"
                    value={newItemInput}
                    onChange={(e) => setNewItemInput(e.target.value)}
                    placeholder="Add new item (e.g., 2 apples #fruits)"
                    InputProps={{
                        endAdornment: (
                            <IconButton type="submit" edge="end">
                                <CheckIcon />
                            </IconButton>
                        )
                    }}
                />
            </Box>
        </Box>
    );
}

export default ShoppingListDetail;