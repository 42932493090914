import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Box } from '@mui/material';

function Home() {
    return React.createElement(
        Box,
        {
            sx: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                padding: 2,
                textAlign: 'center'
            }
        },
        React.createElement(
            Typography,
            {
                variant: 'h4',
                component: 'h1',
                gutterBottom: true,
                sx: { fontWeight: 'bold', mb: 3 }
            },
            "Welcome to Smart Shopping List"
        ),
        React.createElement(
            Typography,
            {
                variant: 'h6',
                gutterBottom: true,
                sx: { mb: 4 }
            },
            "Manage your shopping lists with the power of AI"
        ),
        React.createElement(
            Button,
            {
                component: Link,
                to: "/list",
                variant: "contained",
                color: "primary",
                sx: {
                    py: 1,
                    px: 3,
                    borderRadius: 2,
                    fontWeight: 'bold',
                    '&:hover': {
                        backgroundColor: 'primary.dark'
                    }
                }
            },
            "View Your List"
        )
    );
}

export default Home;