import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { CircularProgress, Box } from '@mui/material';

const PrivateRoute = ({ children }) => {
    const { isAuthenticated, isLoading, user, checkAuth } = useContext(AuthContext);
    const location = useLocation();

    useEffect(() => {
        if (!isLoading && !isAuthenticated && !user) {
            checkAuth();
        }
    }, [isLoading, isAuthenticated, user, checkAuth]);

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (user && !user.hasCompletedOnboarding && location.pathname !== '/onboarding') {
        return <Navigate to="/onboarding" replace />;
    }

    return children;
};

export default PrivateRoute;