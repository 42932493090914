import React, { useState } from 'react';
import {
    Box,
    Typography,
    FormControlLabel,
    Switch,
    Paper,
    Divider
} from '@mui/material';

function Profile() {
    const [user, setUser] = useState({
        name: 'John Doe',
        email: 'john@example.com',
        preferences: {
            darkMode: false,
            notifications: true
        }
    });

    const togglePreference = (pref) => {
        setUser(prevUser => ({
            ...prevUser,
            preferences: {
                ...prevUser.preferences,
                [pref]: !prevUser.preferences[pref]
            }
        }));
    };

    return React.createElement(Box, {
            sx: {
                maxWidth: 400,
                mx: 'auto',
                mt: 4,
                p: 2
            }
        },
        React.createElement(Typography, {
            variant: 'h4',
            component: 'h1',
            gutterBottom: true,
            sx: { fontWeight: 'bold', mb: 3 }
        }, "Your Profile"),
        React.createElement(Paper, {
                elevation: 2,
                sx: { p: 3, mb: 3 }
            },
            React.createElement(Typography, {
                variant: 'h6',
                gutterBottom: true
            }, "Personal Information"),
            React.createElement(Typography, {
                    variant: 'body1',
                    paragraph: true
                },
                React.createElement('strong', null, "Name: "),
                user.name
            ),
            React.createElement(Typography, {
                    variant: 'body1'
                },
                React.createElement('strong', null, "Email: "),
                user.email
            )
        ),
        React.createElement(Paper, {
                elevation: 2,
                sx: { p: 3 }
            },
            React.createElement(Typography, {
                variant: 'h6',
                gutterBottom: true
            }, "Preferences"),
            React.createElement(Divider, { sx: { my: 2 } }),
            React.createElement(FormControlLabel, {
                control: React.createElement(Switch, {
                    checked: user.preferences.darkMode,
                    onChange: () => togglePreference('darkMode'),
                    color: 'primary'
                }),
                label: "Dark Mode",
                sx: { mb: 2 }
            }),
            React.createElement(FormControlLabel, {
                control: React.createElement(Switch, {
                    checked: user.preferences.notifications,
                    onChange: () => togglePreference('notifications'),
                    color: 'primary'
                }),
                label: "Notifications"
            })
        )
    );
}

export default Profile;