import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { Box, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Home as HomeIcon, List as ListIcon, Person as PersonIcon, ExitToApp as ExitToAppIcon } from '@mui/icons-material';

function Layout({ children }) {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Box component="main" sx={{ flexGrow: 1, overflow: 'auto' }}>
                {children}
            </Box>
            <BottomNavigation
                showLabels
                sx={{
                    width: '100%',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0
                }}
            >
                <BottomNavigationAction label="Home" icon={<HomeIcon />} component={Link} to="/" />
                <BottomNavigationAction label="Lists" icon={<ListIcon />} component={Link} to="/list" />
                <BottomNavigationAction label="Profile" icon={<PersonIcon />} component={Link} to="/profile" />
                <BottomNavigationAction label="Logout" icon={<ExitToAppIcon />} onClick={handleLogout} />
            </BottomNavigation>
        </Box>
    );
}

export default Layout;