import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5002/api/v1';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Interceptor per aggiungere il token di autenticazione
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const login = (email, password) => {
    return api.post('/auth/login', { email, password });
};

export const register = (username, email, password) => {
    return api.post('/auth/register', { username, email, password });
};

export const getShoppingLists = () => {
    return api.get('/shoppinglists');
};

export const createShoppingList = (name) => {
    return api.post('/shoppinglists', { name });
};

export const updateShoppingList = (id, name) => {
    return api.put(`/shoppinglists/${id}`, { name });
};

export const deleteShoppingList = (id) => {
    return api.delete(`/shoppinglists/${id}`);
};

export const getShoppingList = (id) => {
    return api.get(`/shoppinglists/${id}`);
};

export const addItemToShoppingList = (id, itemData) => {
    return api.post(`/shoppinglists/${id}/items`, itemData);
};

export const deleteItem = (itemId) => {
    return api.delete(`/items/${itemId}`);
};

export const updateItem = (itemId, itemData) => {
    return api.put(`/items/${itemId}`, itemData);
};

// Nuove funzioni per i suggerimenti AI
export const requestShoppingListSuggestions = (id) => {
    return api.post(`/shoppinglists/${id}/suggestions/request`);
};

export const getShoppingListSuggestions = (id) => {
    return api.get(`/shoppinglists/${id}/suggestions`);
};

export const sendSuggestionFeedback = (id, suggestion, accepted) => {
    return api.post(`/shoppinglists/${id}/suggestions/feedback`, { suggestion, accepted });
};

export const submitOnboardingData = (onboardingData) => {
    return api.post(`/users/onboarding`, onboardingData);
};

export const getUserProfile = () => {
    return api.get('/users/me');
};

export default api;