import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { AuthContext } from '../contexts/AuthContext';
import { submitOnboardingData } from '../services/api';
import {
    Box, Typography, Button, TextField,
    FormControlLabel, Radio, RadioGroup, Alert,
    IconButton, Paper
} from '@mui/material';

const steps = [
    {
        title: "Welcome to Smart Shopping List!",
        description: "Let's get to know you better to provide personalized suggestions.",
        field: "household_size",
        question: "How many people are in your household?",
        type: "number"
    },
    {
        title: "Dietary Preferences",
        description: "Select all that apply:",
        field: "dietary_preferences",
        options: ['Vegetarian', 'Vegan', 'Gluten-free', 'Dairy-free', 'Keto', 'Paleo'],
        type: "multi-select"
    },
    {
        title: "Shopping Habits",
        description: "Tell us about your shopping routine:",
        field: "shopping_frequency",
        question: "How often do you usually go grocery shopping?",
        options: ['Daily', 'Weekly', 'Bi-weekly', 'Monthly'],
        type: "select"
    },
    {
        title: "Cooking Habits",
        description: "Let's talk about your cooking preferences:",
        field: "cooking_habits",
        question: "How often do you cook at home?",
        options: ['Daily', 'A few times a week', 'Weekends only', 'Rarely'],
        type: "select"
    }
];

const Onboarding = () => {
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({
        household_size: '',
        dietary_preferences: [],
        shopping_frequency: '',
        cooking_habits: ''
    });
    const [error, setError] = useState('');
    const { updateOnboardingStatus } = useContext(AuthContext);
    const navigate = useNavigate();
    const [direction, setDirection] = useState(0);
    const [containerHeight, setContainerHeight] = useState('auto');
    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef.current) {
            setContainerHeight(contentRef.current.offsetHeight);
        }
    }, [step]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setError('');
    };

    const handleMultiSelect = (item) => {
        setFormData(prevData => ({
            ...prevData,
            dietary_preferences: prevData.dietary_preferences.includes(item)
                ? prevData.dietary_preferences.filter(i => i !== item)
                : [...prevData.dietary_preferences, item]
        }));
        setError('');
    };

    const handleSelect = (field, value) => {
        setFormData(prevData => ({
            ...prevData,
            [field]: value
        }));
        setError('');
    };

    const validateStep = () => {
        const currentStep = steps[step];
        let isValid = true;

        if (currentStep.type === 'number' && !formData[currentStep.field]) {
            setError('Please enter the number of people in your household.');
            isValid = false;
        }

        if (currentStep.type === 'multi-select' && formData[currentStep.field].length === 0) {
            setError('Please select at least one dietary preference.');
            isValid = false;
        }

        if (currentStep.type === 'select' && !formData[currentStep.field]) {
            setError('Please select an option.');
            isValid = false;
        }

        return isValid;
    };

    const handleNext = () => {
        if (validateStep()) {
            if (step < steps.length - 1) {
                setDirection(1);
                setStep(step + 1);
            } else {
                handleSubmit();
            }
        }
    };

    const handleBack = () => {
        if (step > 0) {
            setDirection(-1);
            setStep(step - 1);
        }
        setError('');
    };

    const handleSubmit = async () => {
        try {
            await submitOnboardingData(formData);
            updateOnboardingStatus(true);
            navigate('/');
        } catch (error) {
            console.error('Error submitting onboarding data:', error);
            setError('An error occurred while submitting your data. Please try again.');
        }
    };

    const currentStep = steps[step];

    const slideVariants = {
        enter: (direction) => ({
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        }),
        center: {
            x: 0,
            opacity: 1
        },
        exit: (direction) => ({
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        })
    };

    const renderStepContent = () => {
        switch (currentStep.type) {
            case 'number':
                return React.createElement(TextField, {
                    type: "number",
                    name: currentStep.field,
                    value: formData[currentStep.field],
                    onChange: handleInputChange,
                    placeholder: currentStep.question,
                    fullWidth: true,
                    InputProps: { inputProps: { min: 1 } }
                });
            case 'multi-select':
                return React.createElement(Box, { display: 'flex', flexWrap: 'wrap', gap: 1 },
                    currentStep.options.map(option =>
                        React.createElement(Button, {
                            key: option,
                            onClick: () => handleMultiSelect(option),
                            variant: formData.dietary_preferences.includes(option) ? 'contained' : 'outlined',
                            color: 'primary',
                            sx: { m: 0.5 }
                        }, option)
                    )
                );
            case 'select':
                return React.createElement(RadioGroup, {
                        name: currentStep.field,
                        value: formData[currentStep.field],
                        onChange: (e) => handleSelect(currentStep.field, e.target.value)
                    },
                    currentStep.options.map(option =>
                        React.createElement(FormControlLabel, {
                            key: option,
                            value: option,
                            control: React.createElement(Radio),
                            label: option
                        })
                    )
                );
            default:
                return null;
        }
    };

    return React.createElement(Box, {
            sx: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                bgcolor: 'background.default',
                p: 2
            }
        },
        React.createElement(AnimatePresence, null,
            error && React.createElement(motion.div, {
                    initial: { opacity: 0, y: -50 },
                    animate: { opacity: 1, y: 0 },
                    exit: { opacity: 0, y: -50 },
                    key: "error"
                },
                React.createElement(Alert, {
                    severity: "error",
                    action: React.createElement(IconButton, {
                        "aria-label": "close",
                        color: "inherit",
                        size: "small",
                        onClick: () => setError('')
                    }, React.createElement(CloseIcon, { fontSize: "inherit" })),
                    sx: { mb: 2, width: '100%', maxWidth: 400 }
                }, error)
            )
        ),
        React.createElement(Paper, {
                elevation: 3,
                sx: {
                    p: 4,
                    width: '100%',
                    maxWidth: 400,
                    mb: 4,
                    borderRadius: 2
                }
            },
            React.createElement(Typography, {
                variant: "h5",
                component: "h2",
                gutterBottom: true
            }, currentStep.title),
            React.createElement(Typography, {
                variant: "body1",
                color: "text.secondary",
                paragraph: true
            }, currentStep.description),
            React.createElement(Box, {
                    sx: {
                        height: containerHeight,
                        position: 'relative',
                        overflow: 'hidden'
                    }
                },
                React.createElement(AnimatePresence, { initial: false, custom: direction, mode: "wait" },
                    React.createElement(motion.div, {
                        key: step,
                        custom: direction,
                        variants: slideVariants,
                        initial: "enter",
                        animate: "center",
                        exit: "exit",
                        transition: {
                            x: { type: "spring", stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 }
                        },
                        style: {
                            position: 'absolute',
                            width: '100%'
                        },
                        ref: contentRef
                    }, renderStepContent())
                )
            ),
            React.createElement(Box, {
                    sx: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 3
                    }
                },
                React.createElement(Button, {
                    onClick: handleBack,
                    disabled: step === 0,
                    startIcon: React.createElement(ChevronLeft),
                    variant: "outlined"
                }, "Back"),
                React.createElement(Button, {
                    onClick: handleNext,
                    endIcon: step < steps.length - 1 ? React.createElement(ChevronRight) : null,
                    variant: "contained"
                }, step < steps.length - 1 ? "Next" : "Finish")
            )
        ),
        React.createElement(Box, {
                sx: {
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    maxWidth: 400,
                    gap: 1
                }
            },
            steps.map((_, index) =>
                React.createElement(Box, {
                    key: index,
                    sx: {
                        height: 8,
                        width: `${100 / steps.length}%`,
                        bgcolor: index <= step ? 'primary.main' : 'grey.300',
                        borderRadius: 4,
                        transition: 'background-color 0.3s'
                    }
                })
            )
        )
    );
};

export default Onboarding;